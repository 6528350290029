import videojs from 'video.js'
import './PlayerRelatedItemsContent'
import './CloseButton'
import * as Fn from './../utils/fn.js'

const Component = videojs.getComponent('Component')
const Dom = videojs.dom

/**
 * Related Items container control
 *
 * @extends Component
 * @class PlayerRelatedItemsContainer
 */
class PlayerRelatedItemsContainer extends Component {
  constructor (player, options) {
    super(player, options)

    this.on(this.player_, 'ended', this.openContainer_)
    this.on(this.player_, 'relatedopen', this.openRelatedScreen)
    this.on(this.player_, 'play', this.closeContainer_)

    this.on(this.player_, 'adstart', () => {
      this.off(this.player_, 'ended', this.openContainer_)
    })

    this.on(this.player_, 'adend', () => {
      this.on(this.player_, 'ended', this.openContainer_)
    })

    if (options.playerOptions.recommendationsShowOnPause) {
      this.on(this.player_, 'manual-pause', this.handleManualPause)
    }
  }

  handleManualPause () {
    this.timeoutID = setTimeout(this.openContainer_, 500)
  }

  openContainer_ = () => {
    if (this.player_.options_.related?.length && this.player_.paused() && this.player_.hasStarted()) {
      this.player_.addClass('vjs-related-open')
      this.openRelatedScreen()
    }
  }

  closeContainer_ () {
    this.player_.removeClass('vjs-related-open')
  }

  initChildren () {
    super.initChildren()
    this.relatedContent = this.getChild('PlayerRelatedItemsContent')

    this.debouncedHandler = Fn.debounce(() => {
      this.scrollHandler()
    }, 100, false, this)

    this.on(this.relatedContent.el(), 'scroll', this.debouncedHandler)
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const el = super.createEl(tag, props, attributes)

    this.controlsContainer = Dom.createEl('div', {
      className: 'vjs-related-controls'
    })
    Dom.appendContent(el, this.controlsContainer)

    this.previousButton = Dom.createEl('span', {
      className: 'vjs-related-previous inactive'
    })
    this.player().on(this.previousButton, 'click', () => {
      this.scrollPrevious()
    })
    Dom.appendContent(this.controlsContainer, this.previousButton)

    this.nextButton = Dom.createEl('span', {
      className: 'vjs-related-next'
    })
    this.player().on(this.nextButton, 'click', () => {
      this.scrollNext()
    })
    Dom.appendContent(this.controlsContainer, this.nextButton)

    return el
  }

  scrollHandler () {
    const itemsContainer = this.relatedContent.el()
    if (itemsContainer.scrollLeft === 0) {
      Dom.removeClass(this.previousButton, 'active')
      Dom.addClass(this.previousButton, 'inactive')
    } else {
      Dom.addClass(this.previousButton, 'active')
      Dom.removeClass(this.previousButton, 'inactive')
    }

    if (this.getScrollPosition() === null) {
      Dom.removeClass(this.nextButton, 'active')
      Dom.addClass(this.nextButton, 'inactive')
    } else {
      Dom.addClass(this.nextButton, 'active')
      Dom.removeClass(this.nextButton, 'inactive')
    }
  }

  openRelatedScreen () {
    const itemsContainer = this.relatedContent.el()
    itemsContainer.scrollLeft = 0
    this.scrollHandler()
  }

  getScrollPosition () {
    const itemsContainer = this.relatedContent.el()
    const items = itemsContainer.querySelectorAll('.related-item')
    const widthContainer = itemsContainer.getBoundingClientRect().width + itemsContainer.getBoundingClientRect().left
    for (let i = 0; i < items.length; i++) {
      // determine position of this item within the div
      const dimensions = items[i].getBoundingClientRect()
      if (widthContainer < dimensions.right) {
        // we can scroll here
        return dimensions.left
      }
    }
    return null
  }

  scrollNext () {
    const itemsContainer = this.relatedContent.el()
    const scrollPosition = this.getScrollPosition()
    const newScrollLeft = scrollPosition + itemsContainer.scrollLeft
    itemsContainer.scrollLeft = newScrollLeft
  }

  scrollPrevious () {
    const itemsContainer = this.relatedContent.el()
    const widthContainer = itemsContainer.getBoundingClientRect().width

    if (itemsContainer.scrollLeft > 0) {
      const newScrollLeft = itemsContainer.scrollLeft - widthContainer
      itemsContainer.scrollLeft = newScrollLeft
    }
  }

  buildCSSClass () {
    return 'vjs-player-related-container'
  }

  dispose () {
    if (this.debouncedHandler) {
      this.debouncedHandler.cancel()
    }
    if (this.timeoutID) {
      clearTimeout(this.timeoutID)
    }
    this.debouncedHandler = null
    super.dispose()
  }
}

/**
 * Default options for `PlayerRelatedItemsContainer`
 *
 * @type {Object}
 * @private
 */
PlayerRelatedItemsContainer.prototype.options_ = {
  children: [
    'playerRelatedItemsContent',
    'closeButton'
  ]
}

Component.registerComponent('PlayerRelatedItemsContainer', PlayerRelatedItemsContainer)
export default PlayerRelatedItemsContainer
