import videojs from 'video.js'
import { PreviewPlugin } from '@agnoplay-aws/agnoplay-plugins'
import './PlayerRelatedItem'
import './UpNextCountdownContainer'
import './CloseButton'

const Component = videojs.getComponent('Component')

/**
 * Related Content control
 *
 * @extends Component
 * @class PlayerRelatedItemsContent
 */
class PlayerRelatedItemsContent extends Component {
  constructor (player, options) {
    super(player, options)
    this.related = options.related
    this.contentEls = []
    this.updateContent(options.related)
    this.on(player, 'playerresize', this.updateContainerWidth)
    this.on(player, 'ended', () => this.updateContentEnded())
  }

  removeContentEls = () => {
    const el = this.el()
    if (el && this.contentEls.length) {
      this.contentEls.forEach((child) => {
        const node = child.el_ || child
        if (el.contains(node)) {
          el.removeChild(node)
        }
      })

      this.contentEls = []
    }
  }

  updateContentEnded = () => {
    this.updateContent(this.related)
  }

  updateContent = (related) => {
    this.related = related
    this.removeContentEls()

    if (related?.length) {
      const {
        recommendationsType,
        upnext,
        countdownDuration,
        upnextRedirect,
        playerId,
        recommendationsPreview,
        playerOptions
      } = this.options_

      const previewPluginItems = []

      this.mapRelatedToType([
        ...(!this.player().ended()
          ? [{
            extra: false,
            image: playerOptions.poster,
            link: 'close',
            name: 'PlayerRelatedItem',
            title: this.localize('Continue watching')
          }]
          : []),
        ...related], recommendationsType).forEach((item) => {
        const child = this.addChild('PlayerRelatedItem', item)
        this.contentEls.push(child.el())

        previewPluginItems.push({
          el: child.el(),
          mediaId: item.videoId
        })
      })

      if (recommendationsPreview) {
        this.previewPlugin = new PreviewPlugin({ items: previewPluginItems })
      }

      if (upnext) {
        const relatedWithVideo = countdownDuration === 0
          ? related.filter((teaser) => teaser.videoId)
          : related

        if (relatedWithVideo?.length) {
          const nextCountdown = this.addChild('UpNextCountdownContainer', {
            ...relatedWithVideo[0],
            countdownDuration,
            recommendationsType,
            upnextRedirect,
            playerId
          })
          this.contentEls.push(nextCountdown.el())
        }
      }
    }
  }

  updateContainerWidth () {
    if (this.player().isFullscreen()) {
      const containerWidth = (window.outerWidth * this.parentComponent_.el().offsetHeight) / window.outerHeight
      if (containerWidth > 0) {
        this.el().style.width = containerWidth + 'px'
        if (containerWidth < 769) {
          this.addClass('related-item-sm')
        } else {
          this.removeClass('related-item-sm')
        }
      }
    }
  }

  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass(),
      innerHTML: `<p class="agnoplayer-related-title"><span>${this.localize('Also watch')}</span></p>`
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return (!this.options_.recommendationsType) || this.options_.recommendationsType === 'default'
      ? 'vjs-player-related-content'
      : 'vjs-player-related-capitan-content'
  }

  mapRelatedToType (related, type = 'default') {
    switch (type) {
      case 'capitanV2':
        return related.slice(0, 6)
      case 'capitanV3':
        return related.slice(0, 6)
      default:
        return related
    }
  }

  dispose () {
    if (this.previewPlugin) {
      this.previewPlugin.remove()
    }
  }
}

Component.registerComponent('PlayerRelatedItemsContent', PlayerRelatedItemsContent)
export default PlayerRelatedItemsContent
