import videojs from 'video.js'
import { THEME_DEFAULT } from './../vars'

const Component = videojs.getComponent('Component')
const VjsPlayToggle = videojs.getComponent('PlayToggle')

class PlayToggle extends VjsPlayToggle {
  buildCSSClass () {
    return this.player_.options_.theme === THEME_DEFAULT
      ? super.buildCSSClass()
      : 'agnoplayer-play-button'
  }

  handleClick (event) {
    super.handleClick(event)
    if (this.player_.paused()) {
      this.player_.trigger('manual-pause')
    }
  }
}

Component.registerComponent('PlayToggle', PlayToggle)
export default PlayToggle
