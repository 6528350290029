'use strict'

import videojs from 'video.js'
import './Player.js'
import 'videojs-shaka'
import './tech/shaka'
import registerHlsjsSourceHandler from './tech/hlsjs'
import 'mux.js'
import 'videojs-mux'
import 'videojs-ima'
import 'videojs-contrib-ads'
import 'videojs-contrib-quality-levels'
import 'videojs-contrib-eme'
import './plugins/AdsManager'
import './plugins/CIMTracker'
import './plugins/Social'
import './plugins/ConvivaIntgr'
import './plugins/PlayerTitleManager'
import './plugins/JumpIntegration'
import './plugins/SeekChapter'
import './plugins/JsonLD'
import './plugins/AgnoplayData.js'
import './plugins/Recommendations.js'
import { SEEK_STEP_DEFAULT, SEEK_STEP_PODCAST_DEFAULT, THEME_DEFAULT, THEME_ADVANCED, THEME_SLIM, THEME_MICRO } from './vars'
import { formatLegacyOrganization } from '@agnoplay/json-config-converter'

require('@silvermine/videojs-chromecast')(videojs, { preloadWebComponents: true })
require('@silvermine/videojs-airplay')(videojs)
require('./assets/styles/base.scss')

const Dom = videojs.dom

const agnoplayer = videojs

agnoplayer.parseConfig = function (options) {
  const parsedOptions = {
    playerId: options.playerId,
    breakpoints: {
      tiny: 200,
      xsmall: 320,
      small: 480,
      medium: 640,
      large: 1204,
      xlarge: 2000,
      huge: 4000
    },
    muted: options.muted || false,
    muxId: options.mux_id || '',
    muxAnalyticsSampleRate: options.muxAnalyticsSampleRate || 100,
    poster: options.poster || ((options.media) ? options.media.metadata.poster : ''),
    fluid: options.layout === 'fluid',
    fill: options.layout === 'fill',
    aspectRatio: typeof (options.aspect_ratio) === 'string' && options.layout === 'fluid'
      ? options.aspect_ratio
      : undefined,
    playerSkinColor: options.playerSkinColor || null,
    playerAdProgressBarColor: options.playerAdProgressBarColor || '#FDC300',
    playerBtnColor: options.playerBtnColor || '#FFFFFF',
    playerBgColor: options.playerBgColor || '#272726',
    playerMenuBgColor: options.playerMenuBgColor || '#272726',
    playerBorderColor: options.playerBorderColor || '#272726',
    playerBorderRadius: options.playerBorderRadius || 2,
    playBtnBgColor: options.play_btn_bg_color || null,
    showCloseButton: options.showCloseButton || false,
    customSeekStep: options.custom_seek_step || SEEK_STEP_DEFAULT,
    clickableLink: options.clickable_link || false,
    pictureInPicture: options.picture_in_picture || 'off',
    pictureInPicturePlatform: options.pictureInPicturePlatform || 'all',
    pictureInPictureSizeMobile: options.picture_in_picture_size_mobile || 70,
    pictureInPictureSizeTablet: options.picture_in_picture_size_tablet || 40,
    pictureInPictureSizeDesktop: options.picture_in_picture_size_desktop || 30,
    audio: options.audio_player || false,
    spotxWebId: options.spotx_web_id || '',
    spotxMobileId: options.spotx_mobile_id || '',
    kachingDfp: options.kaching_dfp || false,
    kachingVastUrl: options.kaching_vast_url || '',
    midroll: options.midroll || false,
    midrollBreakpoint: options.midroll_breakpoint || 90,
    midrollThreshold: options.midrollThreshold || 180,
    dfpCmsid: options.dfp_cmsid || '',
    youboraUsername: options.youbora_username || '',
    youboraAccountcode: options.youbora_account_code || '',
    cimId: options.cim_id || '',
    moatPartnerCode: options.moat_partner_code || '',
    socialButtons: (options.social_buttons && options.socialUrl) || false,
    recommendations: options.recommendations || false,
    related: options.related || [],
    recommendationsType: options.recommendationsType || 'default',
    recommendationsShowOnPause: options.recommendationsShowOnPause || false,
    upnext: options.upnext || false,
    upnextRedirect: (typeof options.upnextRedirect === 'boolean') ? options.upnextRedirect : true,
    recommendationsPreview: options.recommendationsPreview || false,
    autoplay: options.autoplay || false,
    autoplayMuteButton: (typeof options.autoplay_mute_button === 'boolean')
      ? options.autoplay_mute_button
      : true,
    persistVolume: options.persist_volume || false,
    persistMuteState: options.persist_muted_state || false,
    userActions: {
      click: function (event) {
        // https://github.com/videojs/video.js/blob/62f38446a5f01e85739bdb7207c7e992b57c1c86/src/js/player.js#L1975
        if (this.paused()) {
          this.play().then(null, (e) => {})
        } else {
          this.pause()
          this.trigger('manual-pause')
        }
      },
      hotkeys: options.keyboard_controls && {
        seekStep: options.seekStep || options.custom_seek_step || SEEK_STEP_DEFAULT,
        playPauseKey: function (event) {
          if (event.which === 32) {
            this.trigger('manual-pause')
            return true
          }

          return false
        }
      }
    },
    isEmbeddable: options.is_embeddable || false,
    showTitle: options.show_title || false,
    showTitleOnStart: options.show_title_on_start || false,
    titlePosition: options.title_position || 'bottom-left',
    posterTitle: options.poster_title || '',
    showProgressBarInMenu: options.show_progress_bar_in_menu || false,
    showInactiveProgressBar: options.showInactiveProgressBar || false,
    premiumText: options.premiumText || 'PREMIUM',
    hasConviva: options.has_conviva || false,
    convivaApiKey: options.conviva_api_key || null,
    brandLogoPosition: options.brand_logo_position || 'top-right',
    noLogo: options.noLogo || false,
    brandLogoType: options.brand_logo_type || 'default',
    brandImage: options.brand_image,
    customPlayButton: options.custom_play_button || null,
    bigPlayButton: {
      customPlayButton: options.custom_play_button || null,
      isAudioPlayer: options.audio_player || false
    },
    linkUrl: options.link_url || null,
    hasVideoObject: options.has_video_object || false,
    videoSettings: options.video_settings || false,
    isLive: ((options.source_details && options.source_details.is_live) || false),
    controlBarSettings: {
      showProgressBarInMenu: options.show_progress_bar_in_menu || false,
      settingsButton: ((!!options.video_settings && options.video_settings !== 'off') || false),
      liveEdgeTimeDisplay: ((options.source_details && options.source_details.is_live) || false)
    },
    podcastDescription: options.podcastDescription || (
      (options.media && options.media.metadata.description)
        ? options.media.metadata.description
        : false
    ),
    playbackRates: [
      {
        speed: 0.25,
        label: '0.25x'
      },
      {
        speed: 0.5,
        label: '0.5x'
      },
      {
        speed: 1,
        label: '1.0x'
      },
      {
        speed: 1.25,
        label: '1.25x'
      },
      {
        speed: 1.5,
        label: '1.5x'
      },
      {
        speed: 2,
        label: '2.0x'
      }
    ],
    liveui: ((options.source_details && options.source_details.is_live) || false),
    playerHeader: {
      children: [
        (options.social_buttons && options.socialUrl)
          ? 'socialShare'
          : false,
        (options.recommendations)
          ? 'playerRelated'
          : false
      ]
    },
    playerNext: (options.recommendations && options.upnext)
      ? {
        related: options.related
      }
      : false,
    playerEndscreenReplay: (options.theme && options.theme === THEME_ADVANCED)
      ? {
        title: options.poster_title || ''
      }
      : false,
    playerRelatedItemsContainer: options.recommendations
      ? {
        playerRelatedItemsContent: {
          related: options.related,
          recommendationsType: options.recommendationsType || 'default',
          upnext: (options.upnext || false),
          countdownDuration: options.countdown_duration === undefined ? 10 : options.countdown_duration,
          upnextRedirect: (typeof options.upnextRedirect === 'boolean') ? options.upnextRedirect : true,
          recommendationsPreview: options.recommendationsPreview || false,
          playerId: options.playerId
        },
        closeButton: {
          targetClass: 'vjs-related-open',
          eventName: 'relateditemsclosed'
        }
      }
      : false,
    closeButton: (options.picture_in_picture)
      ? {
        targetClass: 'picture-in-picture-open',
        triggerEvent: 'picture-in-picture-close',
        buttonClass: 'pip-close',
        playerEventName: 'pipclosed'
      }
      : false,
    liveOfflineWrapper: (options.source_details && !options.source_details.is_live && options.source_details.live_offline_message)
      ? {
        liveOfflineMessage: options.source_details.live_offline_message,
        poster: options.source_details.poster
      }
      : false,
    language: options.language || 'nl',
    plugins: {
      chromecast: (typeof (options.chromecast) !== 'undefined' && options.chromecast)
        ? Object.assign(
          {
            addButtonToControlBar: false
          },
          options.chromecast_custom_receiver
            ? {
              receiverAppID: options.chromecast_custom_receiver_app_id || 'C082881B'
            }
            : {}
        )
        : false,
      playerTitleManager: (options.show_title && (options.poster_title || options?.media?.metadata?.title))
        ? {
          posterTitle: options.poster_title || options?.media?.metadata?.title,
          showTitle: options.show_title,
          showTitleOnStart: options.show_title_on_start || false,
          titlePosition: options.title_position || 'bottom-left'
        }
        : false,
      jumpIntegration: (options.jumpAnalytics && options.jumpAnalyticsId)
        ? {
          jumpAnalytics: options.jumpAnalytics,
          jumpAnalyticsId: options.jumpAnalyticsId
        }
        : false,
      seekChapter: (options.chapters && options.chaptersUrl)
        ? {
          chapters: options.chapters,
          chaptersUrl: options.chaptersUrl
        }
        : false,
      jsonLD: options.has_video_object
        ? {
          playerId: options.playerId,
          embedUrl: options.embedUrl,
          url: options.url
        }
        : false,
      agnoplayData: options.agnoplayData
        ? {
          brand: options.brand,
          platform: options.platform,
          type: options.type,
          publisher: options.publisher,
          ...(
            typeof options.group === 'number'
              ? formatLegacyOrganization({ config: { group: options.group, organization: options.organization } })
              : { organization: options.group }
          ),
          playerId: options.playerId,
          apiKey: options.agnoplayDataApiKey,
          apiEndpoint: options.agnoplayDataApiEndpoint,
          captureEventType: options.agnoplayDataCaptureEventType
        }
        : false,
      recommendations: options.recommendations
        ? {
          upnext: options.upnext || false,
          recommendationsType: options.recommendationsType,
          related: options.related,
          theme: options.theme
        }
        : false
    },
    adControlBar: {
      hideDuringAds: options.hide_progress_bar_in_ads || 'always-show',
      adVideoAnnouncement: {
        isAudioPlayer: options.audio_player || false
      }
    },
    audioBar: !!options.audio_player,
    chromecast: Object.assign(
      {
        modifyLoadRequestFn: (loadRequest) => {
          // HLS support
          loadRequest.media.hlsSegmentFormat = 'ts'
          // loadRequest.media.hlsVideoSegmentFormat = 'fmp4'ß

          return loadRequest
        }
      },
      typeof (options.poster_title) !== 'undefined'
        ? {
          requestTitleFn: () => options.poster_title
        }
        : {},
      options.chromecast_custom_receiver
        ? {
          requestCustomDataFn: (source) => {
            // widevine and playready only 2 supported on chromecast drm systems
            const keySystem = source.keySystems['com.widevine.alpha'] ||
              source.keySystems['com.microsoft.playready']
            let customData = null

            if (keySystem) {
              const systemType = source.keySystems['com.widevine.alpha']
                ? 'widevine'
                : source.keySystems['com.microsoft.playready']
                  ? 'playready'
                  : null
              const {
                licenseUrl,
                licenseHeaders
              } = keySystem

              customData = {
                drm: {
                  licenseUrl,
                  headers: licenseHeaders,
                  system: systemType
                }
              }
            }

            return customData
          }
        }
        : {}
    ),
    html5: {
      vhs: {
        overrideNative: !videojs.browser.IS_SAFARI,
        limitRenditionByPlayerDimensions: false,
        smoothQualityChange: true,
        experimentalLLHLS: options.experimentalLLHLS
      }
    },
    hlsjsSourceHandler: options.hlsjsSourceHandler || false,
    hideControls: options.hideControls,
    controlPlayerButtonContainer: (options.theme === THEME_ADVANCED || options.theme === THEME_SLIM || options.theme === THEME_MICRO) && !options.hideControls
      ? {
        playerPreviousButton: {
          seekStep: options.seekStep || options.custom_seek_step || ((options.audio_player) ? SEEK_STEP_PODCAST_DEFAULT : SEEK_STEP_DEFAULT)
        },
        playerNextButton: {
          seekStep: options.seekStep || options.custom_seek_step || ((options.audio_player) ? SEEK_STEP_PODCAST_DEFAULT : SEEK_STEP_DEFAULT)
        }
      }
      : false,

    podcastButtonContainer: ((options.theme === THEME_ADVANCED || options.theme === THEME_SLIM) && options.audio_player)
      ? {
        volumePanel: (options.theme === THEME_SLIM)
          ? false
          : {
            inline: false,
            volumeControl: {
              vertical: true
            }
          },
        muteToggle: options.theme === THEME_SLIM
      }
      : false,
    podcastDownloadButton: options.podcastDownloadButton,
    microButtonContainer: options.theme === THEME_MICRO
      ? {
        microCloseButton: options.showCloseButton === true
          ? {
            playerId: options.playerId
          }
          : false
      }
      : false,
    theme: options.theme || THEME_DEFAULT,
    showBigPlayButtonOnPause: (typeof (options.show_play_button_on_pause) !== 'undefined')
      ? options.show_play_button_on_pause
      : false,
    preload: options.preload || 'none',
    vhsSourceRequestHeaders: options.vhsSourceRequestHeaders || null,
    googleAdManager: options.googleAdManager || false,
    gamCmsId: options.gamCmsId || '',
    gamAdUnitId: options.gamAdUnitId || '',
    gamNoPersonalisedAds: options.gamNoPersonalisedAds,
    gamVideoSize: options.gamVideoSize || '640x340',
    gamVideoTargeting: options.gamVideoTargeting || {},
    gamTargeting: options.gamTargeting || {},

    animatedPoster: options.animatedPoster && (options.animatedPosterUrl || (options.media && options.media.features.animatedPoster))
      ? {
        animatedPosterUrl: options.animatedPosterUrl || (options.media && options.media.features.animatedPoster.url),
        animatedPosterFormat: options.animatedPosterFormat || (options.media && options.media.features.animatedPoster.format),
        animationTrigger: options.animatedPosterTrigger || 'mouse',
        animationStartOffset: options.animatedPosterScrollStartOffset || 0.5,
        animationDuration: options.animatedPosterDuration || 4000,
        videoDuration: options.media && options.media.metadata.duration
      }
      : null,
    virtualReality: options.virtualReality && options.virtualRealityProjection
      ? {
        projection: options.virtualRealityProjection
      }
      : false,
    duration: options.media && options.media.metadata.duration,
    chaptersCuesBgColor: options.chapters_cues_bg_color || options.chaptersCuesBgColor,
    loop: options.loop
  }

  if (parsedOptions.hlsjsSourceHandler) {
    parsedOptions.html5.hlsjsConfig = {
      enableWorker: true,
      lowLatencyMode: true,
      backBufferLength: 90,
      ...(options.hlsjsConfig || {})
    }
  }

  if (options.techOrder && Array.isArray(options.techOrder)) {
    parsedOptions.techOrder = ['chromecast', ...options.techOrder]
  }

  const adsManagerConfig = {
    moatPartnerCode: options.moat_partner_code || false,
    midroll: options.midroll,
    midrollBreakpoint: options.midroll_breakpoint,
    midrollThreshold: options.midroll_threshold,
    adsSkip: options.ads_skip,
    noAds: options.media?.metadata?.noAds || false,
    adsPreload: options.ads_preload || null,
    adsThreshold: options.ads_threshold || 30,
    adtimeout: (typeof (options.adtimeout) === 'undefined' || options.adtimeout === 'off')
      ? 60000
      : options.adtimeout,
    videoId: options.videoId,
    prerollTimeout: options.preroll_timeout || 2000,
    preventLateAdStart: !!options.prevent_late_ad_start || false,
    audioPlayer: options.audio_player
  }

  if (options.kaching_dfp && options.kaching_vast_url) {
    parsedOptions.plugins.adsManager = {
      ...adsManagerConfig,
      kachingDfp: options.kaching_dfp,
      kachingVastUrl: options.kaching_vast_url,
      ads: []
    }
  } else if (options.googleAdManager && options.gamAdUnitId) {
    parsedOptions.plugins.adsManager = {
      ...adsManagerConfig,
      googleAdManager: options.googleAdManager,
      gamAdUnitId: options.gamAdUnitId,
      gamCmsId: options.gamCmsId,
      gamNoPersonalisedAds: options.gamNoPersonalisedAds,
      ads: [],
      gamVideoSize: options.gamVideoSize,
      gamVideoTargeting: options.gamVideoTargeting,
      gamTargeting: options.gamTargeting,
      gamAutoPlay: options.autoplay ? 'auto' : 'click',
      gamMutedAutoPlay: options.muted ? 1 : 0
    }
  } else if (Array.isArray(options.ads) && options.ads.length > 0) {
    parsedOptions.plugins.adsManager = {
      ...adsManagerConfig,
      ads: options.ads
    }
  }

  if (typeof (options.abr) !== 'undefined') {
    let abrOptions = {}
    switch (options.abr) {
      case 'performance':
        abrOptions = {
          useBandwidthFromLocalStorage: true,
          enableLowInitialPlaylist: true
        }
        break
      case 'bandwidth':
        abrOptions = {
          enableLowInitialPlaylist: true,
          limitRenditionByPlayerDimensions: true
        }
        break
    }
    parsedOptions.html5.hls = videojs.obj.merge(parsedOptions.html5.hls, abrOptions)
  }

  return parsedOptions
}

agnoplayer.insertPlayer = function (el, options, ready) {
  const oldPlayer = agnoplayer.getPlayer(Dom.$('.video-js', el))
  if (oldPlayer !== undefined) {
    oldPlayer.dispose()
  }
  Dom.emptyEl(el)
  let wrapper = el

  if (options.hlsjsSourceHandler) {
    registerHlsjsSourceHandler()
  }

  // Add a wrapper required for PIP
  if (options.pictureInPicture && options.theme !== THEME_MICRO) {
    const pipWrapper = Dom.createEl('div', {
      className: 'agnoplayer-pip-wrapper'
    })
    Dom.insertContent(el, pipWrapper)
    wrapper = pipWrapper
  }

  let classList = 'video-js vjs-big-play-centered'
  if (options.theme !== THEME_ADVANCED) {
    if (options.showBigPlayButtonOnPause === 'on') {
      classList += ' vjs-show-big-play-button-on-pause'
    } else if (options.showBigPlayButtonOnPause === 'mobile-only' && (videojs.browser.IS_ANDROID || videojs.browser.IS_IOS)) {
      classList += ' vjs-show-big-play-button-on-pause'
    }
  }
  const video = Dom.createEl((options.audio) ? 'audio' : 'video', {
    className: classList
  }, {
    playsinline: 'playsinline',
    'webkit-playsinline': 'webkit-playsinline'
  })
  Dom.insertContent(wrapper, video)

  return agnoplayer(video, options, ready)
}

agnoplayer.VJS_VERSION = videojs.VERSION
agnoplayer.VERSION = process.env.PLAYER_VERSION

export default agnoplayer
