'use strict'

import videojs from 'video.js'
import cssVars from 'css-vars-ponyfill'
import './components/ControlbarShadow'
import './components/PlayerHeader'
import './components/PlayerRelatedItemsContainer'
import './components/PlayerRelatedItemsContent'
import './components/PlayerNext'
import './i18n/nl'
import './i18n/de'
import './i18n/fr'
import './i18n/pt'
import './i18n/lb'
import './components/AudioBar'
import keycode from 'keycode'
import './plugins/PictureInPicture'
import './LiveTracker'
import './components/CloseButton'
import './components/BigDurationDisplay'
import './components/Ads/AdControlBar'
import './components/AutoplayMuteButton'
import './components/PlayerTitle'
import './components/CustomBigPlayButton'
import './components/PlayToggle'
import './components/CustomDurationDisplay'
import './components/CustomPosterImage'
import './components/ClickableOverlay'
import './components/BigMuteToggle'
import './components/Live/SubsCapsButton'
import './components/Live/AudioTrackButton'
import './components/Live/LiveOfflineWrapper'
import './components/SettingsButton'
import './components/PlayerBrandLogo'
import './components/PlayerInactiveProgressbar'
import './components/ControlButtonContainer'
import './components/ControlPlayerButtonContainer'
import './components/PlayerEndscreenReplay'
import './components/Podcast/PodcastButtonContainer'
import './components/Micro/MicroButtonContainer'
import './components/AnimatedPosterContainer'
import './controls/BasicControlBar'
import './controls/AdvancedControlBar'
import './controls/CustomPlayProgressBar'
import './controls/LiveEdgeTimeDisplay'
import './controls/CustomFullscreenToggle'
import './controls/CustomCurrentTimeDisplay'
import './controls/CustomDurationDisplay'
import './plugins/ThumbnailPreview'
import { isEnabled } from './utils/isEnabled'
import localStorageManager from './utils/localStorage'
import { SEEK_STEP_DEFAULT, THEME_ADVANCED, DEFAULT_PEAKS, THEME_SLIM, THEME_MICRO } from './vars'

require('./assets/styles/base.scss')

const videojsPlayer = videojs.getComponent('Player')
const Dom = videojs.dom

const VOLUME_STEP_DEFAULT = 0.1
const BRAND_LOGO_OFFSET = 2
const CLICKABLE_OVERLAY_OFFSET = 2
const ANIMATED_POSTER_OFFSET = 1
const AUTOPLAY_POSTER_DELAY = 2000

const defaults = {
  techOrder: ['chromecast', 'Html5', 'AgnoplayerShaka'],
  fluid: true,
  responsive: true,
  language: 'nl',
  controls: true,
  crossOrigin: 'anonymous',
  liveTracker: {
    liveTolerance: 30,
    trackingThreshold: 60
  },
  autoplayMuteButton: true,
  controlBar: {
    playToggle: false,
    progressControl: false,
    timeDivider: false,
    durationDisplay: false,
    currentTimeDisplay: false,
    remainingTimeDisplay: false,
    liveEdgeTimeDisplay: false,
    volumePanel: false,
    fullscreenToggle: false,
    subtitlesButton: false,
    pictureInPictureToggle: false,
    subsCapsButton: false,
    settingsButton: false,
    playbackRateMenuButton: false,
    chaptersButton: false,
    descriptionsButton: false,
    audioTrackButton: false,
    seekToLive: false,
    customControlSpacer: false,
    liveDisplay: false
  },
  plugins: {
    airPlay: {
      addButtonToControlBar: false
    }
  },
  adControlBar: true,
  userActions: {
    hotkeys: true
  }
}

class Player extends videojsPlayer {
  constructor (tag, options, ready) {
    options = videojs.obj.merge(defaults, options)
    if (options.hideControls) {
      videojs.obj.merge(options, {
        controls: false,
        autoplayMuteButton: false
      })
    } else {
      if (options.theme === THEME_SLIM) {
        options = videojs.obj.merge(options, {
          controlbarShadow: true,
          adMoreInformation: true,
          bigMuteToggle: true,
          advancedControlBar: {
            playToggle: false,
            showProgressBarInMenu: options.controlBarSettings ? options.controlBarSettings.showProgressBarInMenu : false,
            liveEdgeTimeDisplay: options.controlBarSettings ? options.controlBarSettings.liveEdgeTimeDisplay : false
          }
        })
      } else if (options.theme === THEME_ADVANCED) {
        options = videojs.obj.merge(options, {
          controlbarShadow: true,
          adMoreInformation: true,
          bigMuteToggle: true,
          bigDurationDisplay: true,
          playerInactiveProgressbar: options.showInactiveProgressBar,
          advancedControlBar: {
            playToggle: false,
            showProgressBarInMenu: options.controlBarSettings ? options.controlBarSettings.showProgressBarInMenu : false,
            liveEdgeTimeDisplay: options.controlBarSettings ? options.controlBarSettings.liveEdgeTimeDisplay : false
          },
          controlButtonContainer: {
            settingsContainerButton: options.controlBarSettings ? options.controlBarSettings.settingsButton : false
          }
        })
      } else if (options.theme === THEME_MICRO) {
        options = videojs.obj.merge(options, {
          basicControlBar: {
            showProgressBarInMenu: options.controlBarSettings ? options.controlBarSettings.showProgressBarInMenu : false
          }
        })
      } else {
        options = videojs.obj.merge(options, {
          controlbarShadow: false,
          controlButtonContainer: false,
          playerInactiveProgressbar: false,
          adMoreInformation: true,
          bigMuteToggle: false,
          bigDurationDisplay: false,
          playToggle: false,
          basicControlBar: {
            showProgressBarInMenu: options.controlBarSettings ? options.controlBarSettings.showProgressBarInMenu : false,
            liveEdgeTimeDisplay: options.controlBarSettings ? options.controlBarSettings.liveEdgeTimeDisplay : false,
            settingsButton: options.controlBarSettings ? options.controlBarSettings.settingsButton : false
          }
        })
      }
    }

    if (options.autoplay === true || options.autoplay === 'true') {
      options.autoplay = 'play'
    }

    super(tag, options, ready)

    const playerRoot = this.el().parentElement
    if (options.playerSkinColor) {
      playerRoot.style.setProperty('--skin-color', options.playerSkinColor)
    }
    if (options.playerAdProgressBarColor) {
      playerRoot.style.setProperty('--skin-ad-color', options.playerAdProgressBarColor)
    }
    if (options.playerBtnColor) {
      playerRoot.style.setProperty('--btn-color', options.playerBtnColor)
      playerRoot.style.setProperty('--play-button-svg', `url('data:image/svg+xml;charset=utf8,%3C?xml version="1.0" encoding="utf-8"?%3E%3Csvg viewBox="0.161 0.214 19.716 19.595" xmlns="http://www.w3.org/2000/svg"%3E%3Cdefs%3E%3Cstyle%3E.st0{fill:${options.playerBtnColor.replace('#', '%23')}}%3C/style%3E%3C/defs%3E%3Cpath class="st0" d="M7.75102 14.0394L14.0394 10.001L7.75102 5.96259V14.0394ZM10.0027 19.501C8.68874 19.501 7.45371 19.2517 6.29757 18.753C5.14142 18.2543 4.13574 17.5776 3.28052 16.7227C2.42529 15.8679 1.74823 14.8626 1.24935 13.707C0.750464 12.5514 0.501022 11.3166 0.501022 10.0027C0.501022 8.68876 0.750356 7.45372 1.24902 6.29759C1.74769 5.14144 2.42444 4.13576 3.27927 3.28054C4.13412 2.42531 5.13936 1.74825 6.295 1.24936C7.45061 0.75048 8.68539 0.501038 9.99932 0.501038C11.3133 0.501038 12.5483 0.750372 13.7044 1.24904C14.8606 1.74771 15.8663 2.42445 16.7215 3.27929C17.5767 4.13414 18.2538 5.13938 18.7526 6.29501C19.2515 7.45063 19.501 8.68541 19.501 9.99934C19.501 11.3133 19.2516 12.5483 18.753 13.7044C18.2543 14.8606 17.5776 15.8663 16.7227 16.7215C15.8679 17.5767 14.8626 18.2538 13.707 18.7527C12.5514 19.2515 11.3166 19.501 10.0027 19.501ZM10.001 18.001C12.2343 18.001 14.126 17.226 15.676 15.676C17.226 14.126 18.001 12.2343 18.001 10.001C18.001 7.76768 17.226 5.87601 15.676 4.32601C14.126 2.77601 12.2343 2.00101 10.001 2.00101C7.76766 2.00101 5.876 2.77601 4.326 4.32601C2.776 5.87601 2.001 7.76768 2.001 10.001C2.001 12.2343 2.776 14.126 4.326 15.676C5.876 17.226 7.76766 18.001 10.001 18.001Z"/%3E%3C/svg%3E')`)
      playerRoot.style.setProperty('--play-button-thin-svg', 'url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI0LjEuMiwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA2MCA2MCIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgNjAgNjA7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkZGRkZGO30KCS5zdDF7ZmlsbC1ydWxlOmV2ZW5vZGQ7Y2xpcC1ydWxlOmV2ZW5vZGQ7ZmlsbDojRkZGRkZGO30KCS5zdDJ7ZmlsbDpub25lO3N0cm9rZTojRkZGRkZGO3N0cm9rZS13aWR0aDoyO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTMwLDYwQzEzLjQsNjAsMCw0Ni42LDAsMzBTMTMuNCwwLDMwLDBzMzAsMTMuNCwzMCwzMFM0Ni42LDYwLDMwLDYweiBNMzAsNTguMWMxNS41LDAsMjguMS0xMi42LDI4LjEtMjguMQoJUzQ1LjUsMS45LDMwLDEuOVMxLjksMTQuNSwxLjksMzBTMTQuNSw1OC4xLDMwLDU4LjF6Ii8+CjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1IDI1LjY0NSAyOS41MTYpIj4KCTxnPgoJCTxwYXRoIGlkPSJhIiBjbGFzcz0ic3QxIiBkPSJNMTUuNSwyMWwyMC45LTMuNGMwLjUtMC4xLDEuMSwwLjMsMS4xLDAuOGMwLDAuMSwwLDAuMiwwLDAuM2wtMy40LDIwLjljLTAuMSwwLjUtMC42LDAuOS0xLjEsMC44CgkJCWMtMC4yLDAtMC40LTAuMS0wLjUtMC4zTDE0LjksMjIuN2MtMC40LTAuNC0wLjQtMSwwLTEuNEMxNS4xLDIxLjIsMTUuMywyMS4xLDE1LjUsMjF6Ii8+Cgk8L2c+Cgk8cGF0aCBjbGFzcz0ic3QyIiBkPSJNMTUuNiwyMmwxNy41LDE3LjVsMy40LTIwLjlMMTUuNiwyMkwxNS42LDIyeiIvPgo8L2c+Cjwvc3ZnPgo=")')
      playerRoot.style.setProperty('--pause-button-svg', `url('data:image/svg+xml;charset=utf8,%3C?xml version="1.0" encoding="utf-8"?%3E%3Csvg viewBox="-0.215 -0.055 12.544 14.135" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M7.99997 13.5133V0.513397H11.75V13.5133H7.99997ZM0.25 13.5133V0.513397H3.99997V13.5133H0.25Z" fill="${options.playerBtnColor.replace('#', '%23')}"/%3E%3C/svg%3E')`)
      playerRoot.style.setProperty('--close-button-svg', `url('data:image/svg+xml;charset=utf8,%3C?xml version="1.0" encoding="utf-8"?%3E%3Csvg viewBox="-0.053 0.107 13.934 13.813" fill="none" xmlns="http://www.w3.org/2000/svg"%3E%3Cpath d="M1.40002 13.6672L0.346191 12.6134L5.94619 7.01337L0.346191 1.41337L1.40002 0.359543L7.00002 5.95954L12.6 0.359543L13.6538 1.41337L8.05384 7.01337L13.6538 12.6134L12.6 13.6672L7.00002 8.06719L1.40002 13.6672Z" fill="${options.playerBtnColor.replace('#', '%23')}"/%3E%3C/svg%3E')`)
      playerRoot.style.setProperty('--next-button-svg', `url('data:image/svg+xml;charset=utf8,%3Csvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 24 28" style="enable-background:new 0 0 24 28" xml:space="preserve"%3E%3Cstyle%3E.st0{fill:${options.playerBtnColor.replace('#', '%23')}}%3C/style%3E%3Cpath class="st0" d="M12 3.6C5.4 3.6 0 9 0 15.6s5.4 12 12 12 12-5.4 12-12c0-1.9-.4-3.7-1.2-5.3l-1.3.7c.7 1.4 1.1 3 1.1 4.6 0 5.8-4.7 10.5-10.5 10.5S1.5 21.4 1.5 15.6 6.2 5.1 12 5.1c.6 0 1.2.1 1.8.2l.2-1.6c-.7-.1-1.3-.1-2-.1z"/%3E%3Cpath class="st0" d="m11.7.3 3.7 4.1-3.7 4.1-1.1-1 2.8-3-2.8-3L11.7.3z"/%3E%3Cpath style="fill:none" d="M-.5 10.1h25v19.1h-25z"/%3E%3C/svg%3E')`)
      playerRoot.style.setProperty('--previous-button-svg', `url('data:image/svg+xml;charset=utf8,%3Csvg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 24 28" style="enable-background:new 0 0 24 28" xml:space="preserve"%3E%3Cstyle%3E.st0{fill:${options.playerBtnColor.replace('#', '%23')}}%3C/style%3E%3Cpath class="st0" d="M12 3.6c6.6 0 12 5.4 12 12s-5.4 12-12 12-12-5.4-12-12c0-1.9.4-3.7 1.2-5.3l1.3.7c-.7 1.4-1.1 3-1.1 4.6 0 5.8 4.7 10.5 10.5 10.5s10.5-4.7 10.5-10.5S17.7 5.1 11.9 5.1c-.6 0-1.2.1-1.8.2L10 3.7c.7-.1 1.3-.1 2-.1z"/%3E%3Cpath class="st0" d="M12.3.3 8.6 4.4l3.7 4.1 1.1-1-2.8-3 2.8-3L12.3.3z"/%3E%3Cpath style="fill:none" d="M-.5 10.1h25v19.1h-25z"/%3E%3C/svg%3E')`)
    }
    if (options.playerBgColor) {
      playerRoot.style.setProperty('--bg-color', options.playerBgColor)
    }
    if (options.playerMenuBgColor) {
      playerRoot.style.setProperty('--menu-bg-color', options.playerMenuBgColor)
    }
    if (options.playerBorderColor) {
      playerRoot.style.setProperty('--border-color', options.playerBorderColor)
    }
    if (options.playerBorderRadius) {
      playerRoot.style.setProperty('--border-radius', `${options.playerBorderRadius ? options.playerBorderRadius : 0}px`)
    }
    if (options.playBtnBgColor) {
      playerRoot.style.setProperty('--play-btn-bg-color', options.playBtnBgColor)
    }
    if (options.chaptersCuesBgColor) {
      playerRoot.style.setProperty('--chapters-cue-bg-color', options.chaptersCuesBgColor)
    }
    playerRoot.style.setProperty('--pip-size-mobile', `${options.pictureInPictureSizeMobile}%`)
    playerRoot.style.setProperty('--pip-size-tablet', `${options.pictureInPictureSizeTablet}%`)
    playerRoot.style.setProperty('--pip-size-desktop', `${options.pictureInPictureSizeDesktop}%`)
    playerRoot.style.setProperty('--close-btn-width', options.showCloseButton === true ? '0em' : '2.5em')

    // fix IE 11 style issues
    cssVars({
      watch: true,
      onlyLegacy: true,
      variables: {
        '--skin-color': options.playerSkinColor,
        '--skin-ad-color': options.playerAdProgressBarColor,
        '--play-btn-bg-color': options.playBtnBgColor,
        '--chapters-cue-bg-color': options.chaptersCuesBgColor,
        '--pip-size-mobile': `${options.pictureInPictureSizeMobile}%`,
        '--pip-size-tablet': `${options.pictureInPictureSizeTablet}%`,
        '--pip-size-desktop': `${options.pictureInPictureSizeDesktop}%`
      }
    })

    if (options.autoplay !== false) {
      this.addClass('agnoplayer-autoplay')
      this.setTimeout(() => {
        this.removeClass('agnoplayer-autoplay')
      }, AUTOPLAY_POSTER_DELAY)
    }

    this.ready(() => {
      if (options.hideControls) {
        this.on('touchstart', function (event) {
          if (event.target.nodeName === 'VIDEO') {
            if (this.paused()) {
              this.play()
            } else {
              this.pause({
                isManual: true
              })
            }
          }
        })
      }
      if (options.persistVolume) {
        const volume = localStorageManager.get('agnoplayer-volume')
        if (volume !== null) {
          this.volume(volume)
          this.trigger('volumechange')
        }

        this.on('volumechange', () => {
          localStorageManager.set('agnoplayer-volume', this.volume())
        })
      }

      if (options.persistMuteState) {
        const muted = localStorageManager.get('agnoplayer-muted')
        if (muted) {
          this.muted(true)
        }
      }

      // Enable DRM support
      this.eme()

      if (options.virtualReality) {
        this.vr({
          projection: 'AUTO',
          forceCardboard: false
        })
      }
    })

    this.on('mouseleave', () => {
      // setTimeout is used to put this custom handler after the default
      // handlers of video.js itself in the execution queue
      this.setTimeout(() => {
        this.userActive(false)
      }, 0)
    })

    this.on('loadstart', () => {
      const vhs = this.tech(true).vhs

      if (vhs && options.vhsSourceRequestHeaders) {
        vhs.xhr.beforeRequest = function (beforeRequestoptions) {
          if (!beforeRequestoptions.headers) {
            beforeRequestoptions.headers = options.vhsSourceRequestHeaders
          } else {
            Object.assign(beforeRequestoptions.headers, options.vhsSourceRequestHeaders)
          }

          return beforeRequestoptions
        }
      }

      if (typeof (this.src()) !== 'undefined' && this.src().indexOf('mp4') === -1) {
        return
      }
      const progressiveSources = this.currentSources().filter((item) => item.type === 'video/mp4')
      if (progressiveSources.length > 1) {
        progressiveSources.forEach((item, index) => {
          this.qualityLevels().addQualityLevel({
            id: item.label,
            height: item.height,
            width: item.width,
            bandwidth: item.bandwidth,
            type: 'video/mp4',
            enabled: (enabled) => {
              if (enabled === undefined) {
                return isEnabled(this, item)
              } else if (enabled) {
                const currentTime = this.currentTime()
                // Create a new sourceSet. With the newly selected source at the top
                let sources = this.currentSources()
                sources = sources.filter(src => src !== item)
                sources.unshift(item)

                const wasPlaying = !this.paused()

                // Prevent the poster image from appearing briefly.
                this.addClass('agnoplayer-autoplay')
                this.setTimeout(() => {
                  this.removeClass('agnoplayer-autoplay')
                }, AUTOPLAY_POSTER_DELAY)

                this.src(sources)
                this.autoplay(false)

                const events = ['canplay', 'play', 'contentplay']
                if ((videojs.browser.IS_ANDROID || videojs.browser.IS_IOS)) {
                  events.push('loadedmetadata')
                }
                const resumePlay = () => {
                  this.off(events, resumePlay)
                  this.hasStarted(true)
                  this.play().finally(() => {
                    this.currentTime(currentTime)
                    if (!wasPlaying) {
                      this.pause()
                    }
                  })
                }
                this.one(events, resumePlay)
              }
            }
          })

          if (this.qualityLevels().selectedIndex_ === -1) {
            this.qualityLevels().selectedIndex_ = 0
          }
        })

        this.qualityLevels().trigger('change')
      }
    })
    if (options.pictureInPicture && options.pictureInPicture !== 'off') {
      this.pictureInPicture().setState({ customClass: options.pictureInPicture })
    }

    if (options.isLive) {
      this.addClass('agnoplayer-live')
    }

    this.addClass('agnoplayer-theme-' + options.theme)

    if (options.brandImage && options.brandLogoPosition !== 'off' && options.noLogo !== true) {
      const controlBarIndex = this.children().indexOf(this.controlBar)
      this.addChild('PlayerBrandLogo', {
        brandLogoType: options.brandLogoType,
        brandLogoPosition: options.brandLogoPosition,
        brandImage: options.brandImage
      }, controlBarIndex - BRAND_LOGO_OFFSET)
    }

    if (options.clickableLink && options.linkUrl) {
      const bigPlayButtonIndex = this.children().indexOf(this.bigPlayButton)
      this.addChild('ClickableOverlay', {
        linkUrl: options.linkUrl
      }, bigPlayButtonIndex - CLICKABLE_OVERLAY_OFFSET)
    }

    if (options.animatedPoster) {
      const posterImageIndex = this.children().indexOf(this.posterImage)
      this.addChild('AnimatedPosterContainer', options.animatedPoster, posterImageIndex + ANIMATED_POSTER_OFFSET)
    }

    this.removeButtonTitle()

    if (window.WebKitPlaybackTargetAvailabilityEvent) {
      const mediaEl = this.el().querySelector('video, audio')
      mediaEl.addEventListener('webkitcurrentplaybacktargetiswirelesschanged', function (event) {
        Dom.toggleClass(this.el(), 'vjs-airplay')
      }.bind(this))
    }
  }

  pause (props) {
    super.pause()

    if (props?.isManual) {
      this.trigger('manual-pause')
    }
  }

  getEmeConfig (drm) {
    const emeConfig = {
      keySystems: {}
    }

    if (drm.commonHeaders) {
      emeConfig.emeHeaders = drm.commonHeaders
    }

    if (drm.widevine) {
      emeConfig.keySystems['com.widevine.alpha'] = {
        url: drm.widevine.licenseUrl
      }

      if (drm.widevine.licenseHeaders) {
        emeConfig.keySystems['com.widevine.alpha'].licenseHeaders = drm.widevine.licenseHeaders
      }
    }

    if (drm.playready) {
      emeConfig.keySystems['com.microsoft.playready'] = {
        url: drm.playready.licenseUrl
      }

      if (drm.playready.licenseHeaders) {
        emeConfig.keySystems['com.microsoft.playready'].licenseHeaders = drm.playready.licenseHeaders
      }
    }

    if (drm.fairplay) {
      emeConfig.keySystems['com.apple.fps.1_0'] = {
        certificateUri: drm.fairplay.certificateUrl,
        licenseUri: drm.fairplay.licenseUrl
      }

      if (drm.fairplay.certificateHeaders) {
        emeConfig.keySystems['com.apple.fps.1_0'].certificateHeaders = drm.fairplay.certificateHeaders
      }

      if (drm.fairplay.licenseHeaders) {
        emeConfig.keySystems['com.apple.fps.1_0'].licenseHeaders = drm.fairplay.licenseHeaders
      }
    }

    return emeConfig
  }

  src (src) {
    // AGNOPlay still call the source, even when metadata says stream isn't availale.
    // Disable playback completely.
    if (this.options_.liveOfflineWrapper !== false) {
      return
    }

    if (typeof src !== 'undefined' && Array.isArray(src)) {
      // we set the handleManifestRedirects option here always to true so redirect streams from NEP will work (AG-318)
      for (let i = 0; i < src.length; i++) {
        if (typeof src[i].handleManifestRedirects === 'undefined') {
          src[i].handleManifestRedirects = true
        }

        if (this.isAudio()) {
          src[i].peaks = DEFAULT_PEAKS
        }

        // Configure DRM options per specific source for eme plugin
        const { drm } = src[i]
        if (drm) {
          const emeConfig = this.getEmeConfig(drm)
          Object.assign(src[i], emeConfig)
          delete src[i].drm
        }
      }
    }

    return super.src(src)
  }

  removeButtonTitle () {
    const buttons = document.getElementsByClassName('vjs-button')
    const player = this
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].onmouseover = function (e) {
        if (this.hasAttribute('title')) {
          this.removeAttribute('title')
        }
        // note: this works in advanced theme because the tooltip isn't shown there
        // somehow, checking on the player class 'agnoplayer-theme-basic' does not work here
        this.querySelector('.vjs-control-text').style.left = ''
        this.querySelector('.vjs-control-text').style.right = ''
        const tooltipLeft = (this.querySelector('.vjs-control-text').getBoundingClientRect().width / 2) - (this.getBoundingClientRect().width / 2)
        const playerBounds = player.el().getBoundingClientRect()
        const toolTipBounds = this.querySelector('.vjs-control-text').getBoundingClientRect()
        this.querySelector('.vjs-control-text').style.right = '5%'

        if (toolTipBounds.right - tooltipLeft > playerBounds.right) {
          this.querySelector('.vjs-control-text').style.left = ''
          this.querySelector('.vjs-control-text').style.right = '10px'
        } else if (toolTipBounds.left - tooltipLeft < playerBounds.left) {
          this.querySelector('.vjs-control-text').style.right = ''
          this.querySelector('.vjs-control-text').style.left = '10px'
        }
      }
    }
  }

  hasAdvancedTheme () {
    return this.options().theme === THEME_ADVANCED
  }

  isAdPlaying () {
    return typeof this.player_?.ads?.isAdPlaying === 'function'
      ? this.player_.ads.isAdPlaying()
      : false
  }

  // https://videojs.github.io/videojs-contrib-ads/integrator/api.html isAdPlaying
  isPausedAdOrVideo = () => {
    return this.ads && this.ads.isAdPlaying && this.ads.isAdPlaying()
      ? this.ima && !this.ima.controller.sdkImpl.adPlaying
      : this.paused()
  }

  requestPictureInPicture () {
    if (this.played().length > 0 || this.isAdPlaying()) {
      this.setPipPositionOffset()
      this.addClass('picture-in-picture-open')
      this.isInPictureInPicture(true)
      this.trigger('enterpictureinpicture')
    }
  }

  setPipPositionOffset () {
    this.el().style.top = '20px'
    this.el().style.bottom = '100%'
    this.el().style.width = this.currentWidth() + 'px'
    this.el().style.height = this.currentHeight() + 'px'
    this.el().style.right = this.el().getBoundingClientRect().right + 'px'
  }

  exitPictureInPicture () {
    this.removeClass('picture-in-picture-open')
    this.isInPictureInPicture(false)
    this.el().removeAttribute('style')
    this.trigger('leavepictureinpicture')
  }

  /**
   * Called when this Player receives a hotkey keydown event.
   * Supported player-wide hotkeys are:
   *
   *   f          - toggle fullscreen
   *   m          - toggle mute
   *   k or Space - toggle play/pause
   *
   * @param {EventTarget~Event} event
   *        The `keydown` event that caused this function to be called.
   *
   * @override
   */
  handleHotkeys (event) {
    if (typeof (this.ads) !== 'undefined' && typeof (this.ads.isInAdMode) !== 'undefined' && this.ads.isInAdMode()) {
      return
    }

    // Filter out cmd and windows keys
    if (event.metaKey || event.ctrlKey || event.metaKey) {
      return
    }

    // Super deals with fullscreen, mute and play/pause
    super.handleHotkeys(event)

    const hotkeys = this.options_.userActions ? this.options_.userActions.hotkeys : {}
    const seekStep = hotkeys.seekStep ?? SEEK_STEP_DEFAULT
    const volumeStep = hotkeys.volumeStep ?? VOLUME_STEP_DEFAULT

    const {
      forwardKey = keydownEvent => keycode.isEventKey(keydownEvent, 'right'),
      rewindKey = keydownEvent => keycode.isEventKey(keydownEvent, 'left'),
      volumeUpKey = keydownEvent => keycode.isEventKey(keydownEvent, 'up'),
      volumeDownKey = keydownEvent => keycode.isEventKey(keydownEvent, 'down')
    } = hotkeys

    if (forwardKey.call(this, event)) {
      event.preventDefault()
      event.stopPropagation()

      this.seekForward(seekStep)
    } else if (rewindKey.call(this, event)) {
      event.preventDefault()
      event.stopPropagation()

      this.seekBackward(seekStep)
    } else if (volumeUpKey.call(this, event)) {
      event.preventDefault()
      event.stopPropagation()
      this.volume(this.volume() + volumeStep)
    } else if (volumeDownKey.call(this, event)) {
      event.preventDefault()
      event.stopPropagation()
      this.volume(this.volume() - volumeStep)
    }
  }

  seekForward (seekStep) {
    const wasPlaying = !this.paused()
    if (wasPlaying) {
      this.pause()
    }
    const seekTime = Math.min(this.currentTime() + seekStep, this.duration() - 0.001)
    this.currentTime(seekTime)
    if (wasPlaying) {
      this.play()
    }
  }

  seekBackward (seekStep) {
    const wasPlaying = !this.paused()
    if (wasPlaying) {
      this.pause()
    }
    const seekTime = Math.max(this.currentTime() - seekStep, 0)
    this.currentTime(seekTime)
    if (wasPlaying) {
      this.play()
    }
  }

  videoMetadata (data) {
    if (data === undefined) {
      return this.videoMetadata_
    }
    this.videoMetadata_ = data
    this.trigger('videometadataupdated')
  }

  /**
   * Proxied methods below.
   */

  /**
   * Proxy for .dispose()
   */
  destroy () {
    this.dispose()
  }

  /**
   * proxy for .el()
   */
  get element () {
    return this.el()
  }

  /**
   * Proxy for `on` but allowing only event and callback to be defined.
   *
   * @param String type
   * @param function fn
   */
  addEventListener (type, fn) {
    this.on(type, fn)
  }

  /**
   * Proxy for `off`
   *
   * @param String type
   * @param function fn
   */
  removeEventListener (type, fn) {
    this.off(type, fn)
  }

  seekRange () {
    if (typeof (this.tech_.seekRange) !== 'undefined') {
      return this.tech_.seekRange()
    }
  }

  doReset_ () {
    if (this.tech_) {
      this.tech_.clearTracks('text')
    }
    this.resetCache_()
    this.poster('')
    this.loadTech_('Html5', null)
    this.techCall_('reset')
    this.resetControlBarUI_()
    this.trigger('playerreset')
  }

  muted (muted) {
    if (typeof (muted) !== 'undefined' && this.options_.persistMuteState) {
      localStorageManager.set('agnoplayer-muted', muted)
    }

    return super.muted(muted)
  }

  volume (percentAsDecimal) {
    return super.volume(percentAsDecimal)
  }
}

videojsPlayer.registerComponent('Player', Player)
export default Player
