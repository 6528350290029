import videojs from 'video.js'
import { defaultBigPlayButton, defaultAudioPlayButton } from '../utils/svgs'

const Component = videojs.getComponent('Component')
const BigPlayButton = videojs.getComponent('BigPlayButton')
const Dom = videojs.dom

/**
 * Custom BigPlay Button control
 *
 * @extends BigPlayButton
 * @class CustomBigPlayButton
 */
class CustomBigPlayButton extends BigPlayButton {
  createEl (tag, props = {}, attributes = {}) {
    const el = super.createEl(tag, props, attributes)
    let decodedImage = this.options().isAudioPlayer
      ? defaultAudioPlayButton
      : defaultBigPlayButton
    if (this.options().customPlayButton) {
      decodedImage = atob(this.options().customPlayButton)
    }
    const content = Dom.createEl('div', {
      innerHTML: decodedImage,
      className: 'vjs-custom-big-play-button'
    })

    Dom.appendContent(el, content)
    return el
  }

  constructor (player, options) {
    super(player, options)
    if (this.options().customPlayButton) {
      this.addClass('agnoplayer-custom-button')
    }

    // TODO: Find a better solution
    // Hide element until custom css is loaded to prevent FOUC
    this.el().style.visibility = 'hidden'
    this.addClass('agnoplayer-show-on-css-load')
  }

  handleClick (event) {
    if (this.player_.ads && this.player_.ima && this.player_.ima.controller && this.player_.ads.isAdPlaying()) {
      this.player_.ima.controller.onAdPlayPauseClick()
      return false
    }
    super.handleClick(event)

    if (this.player_.paused()) {
      this.player_.trigger('manual-pause')
    }
  }
}

Component.registerComponent('BigPlayButton', CustomBigPlayButton)
export default CustomBigPlayButton
