import videojs from 'video.js'

const ClickableComponent = videojs.getComponent('ClickableComponent')
const Dom = videojs.dom

/**
 * Related Content control
 *
 * @extends ClickableComponent
 * @class PlayerRelatedItem
 */
class PlayerRelatedItem extends ClickableComponent {
  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    const el = super.createEl(tag, props, attributes)

    const content = Dom.createEl('div', {
      innerHTML: `<div data-spark-image="true" class="related-item-image" style="background-image: url('${this.options_.image}')"></div>`,
      className: 'related-item-content'
    })
    Dom.appendContent(el, content)

    const playbtn = Dom.createEl('div', {
      className: 'related-item-play-btn'
    })
    Dom.appendContent(content, playbtn)

    const title = Dom.createEl('div', {
      textContent: this.options_.title,
      className: 'related-item-title'
    })
    Dom.appendContent(content, title)

    if (this.options_.extra) {
      const premiumText = Dom.createEl('div', {
        textContent: this.options_.premiumText || this.player()
          .localize('PREMIUM'),
        className: 'vjs-premium-text'
      })
      Dom.appendContent(content, premiumText)
    }

    return el
  }

  buildCSSClass () {
    return 'related-item'
  }

  handleClick (event) {
    if (this.options_.link === 'close') {
      this.player_.removeClass('vjs-related-open')
      this.player_.play()
      return
    }
    this.player_.trigger('relateditemclick')
    window.location = this.options_.link
  }
}

ClickableComponent.registerComponent('PlayerRelatedItem', PlayerRelatedItem)
export default PlayerRelatedItem
