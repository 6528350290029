import videojs from 'video.js'
import window from 'global/window'
import 'blockadblock'
import '../components/Ads/AdAnnouncement'
import { isPromise } from './../utils/promise'
import getVastUrl from '../utils/vastUrlGenerator'

let google
const blockAdBlock = window.blockAdBlock
blockAdBlock.setOption({
  checkOnLoad: false,
  resetOnEnd: true
})

const Plugin = videojs.getPlugin('plugin')

class AdsManager extends Plugin {
  constructor (player, options) {
    super(player)
    this.options_ = options

    // Copy values so modifying the local copy doesn't affect the global configuration.
    // This is needed because agnoplay might re-instantiate us using the same configuration object
    // So modifying it here would result in less ads to be displayed after re-instantiation
    if (options.ads) {
      this.options_.ads = JSON.parse(JSON.stringify(options.ads))
    }

    if (!player.hasPlugin('ima')) {
      throw new Error('Required plugin missing: videojs-ima')
    }

    if (options.midroll) {
      this.player.addChild('AdAnnouncement')
    }

    // TODO SpotX integration
    if (!blockAdBlock) {
      this.player.trigger('adblock')
    } else {
      blockAdBlock.onDetected(() => {
        player.trigger('adblock')
      })
      blockAdBlock.check()

      player.ready(this.ready.bind(this))
    }
  }

  getCuePoints () {
    return this.player.ima.getAdsManager() ? this.player.ima.getAdsManager().getCuePoints() : []
  }

  /**
   * Ready func called after player is ready.
   * Ensure all dependancies are loaded and player is ready to receive commands.
   */
  ready () {
    google = window.google
    // While this is most likely the cause of an ad-blocker. it might not be.
    // Yet, we can't do anything without the SDK being loaded so simply fail gracefully
    if (typeof (google) === 'undefined') {
      return
    }
    this.initializeEventMap()

    const imaOptions = {
      locale: this.player.language_,
      showCountdown: false,
      timeout: this.options_.adtimeout,
      prerollTimeout: this.options_.prerollTimeout,
      debug: false,
      disableAdControls: true,
      preventLateAdStart: this.options_.preventLateAdStart,
      contribAdsSettings: {
        liveCuePoints: false
      }
    }

    if (!this.options_.noAds) {
      if (this.options_.kachingDfp && this.options_.kachingVastUrl) {
        const vastUrl = this.options_.kachingVastUrl
        // Required to init immediatly
        this.vastURL = vastUrl
        this.player.ima(videojs.obj.merge(imaOptions, {
          adTagUrl: vastUrl
        }))
      } else if (this.options_.googleAdManager && this.options_.gamAdUnitId) {
        const vastUrl = getVastUrl(this.options_)
        this.player.ima(videojs.obj.merge(imaOptions, {
          adTagUrl: vastUrl
        }))
      } else if (Array.isArray(this.options_.ads) && this.options_.ads.length > 0) {
        this.nextAd = this.options_.ads.shift()
        this.player.ima(videojs.obj.merge(imaOptions, {
          adTagUrl: (this.nextAd !== undefined) ? this.nextAd.sources : null
        }))
      }
    }

    this.addEventListeners()
  }

  initializeEventMap () {
    if (google) {
      this.eventMap_ = [{
        adEvent: google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
        analyticsEvent: 'adbreakend'
      },
      {
        adEvent: google.ima.AdEvent.Type.FIRST_QUARTILE,
        analyticsEvent: 'adfirstquartile'
      },
      {
        adEvent: google.ima.AdEvent.Type.LOADED,
        analyticsEvent: 'adloaded'
      },
      {
        adEvent: google.ima.AdEvent.Type.MIDPOINT,
        analyticsEvent: 'admidpoint'
      },
      {
        adEvent: google.ima.AdEvent.Type.PAUSED,
        analyticsEvent: 'adpaused'
      },
      {
        adEvent: google.ima.AdEvent.Type.RESUMED,
        analyticsEvent: 'adresumed'
      },
      {
        adEvent: google.ima.AdEvent.Type.STARTED,
        analyticsEvent: 'adbegin'
      },
      {
        adEvent: google.ima.AdEvent.Type.IMPRESSION,
        analyticsEvent: 'adimpression'
      },
      {
        adEvent: google.ima.AdEvent.Type.THIRD_QUARTILE,
        analyticsEvent: 'adthirdquartile'
      },
      {
        adEvent: google.ima.AdEvent.Type.AD_ERROR,
        analyticsEvent: 'aderror'
      },
      {
        adEvent: 'ads-ad-started',
        analyticsEvent: 'adbreakbegin'
      },
      {
        adEvent: 'adserror',
        analyticsEvent: 'aderror'
      }]
    } else {
      this.eventMap_ = []
    }
  }

  loadVastURL (vastURL) {
    // Prevent double-load
    if (this.vastURL === vastURL) {
      return
    }

    // Tell ima to update its VAST tag and immediatly request them.
    this.vastURL = vastURL
    this.player.ima.changeAdTag(this.vastURL)
    this.player.ima.requestAds()
  }

  addEventListeners () {
    this.player.on('ads-ad-started', this.onAdEvent.bind(this))
    this.player.on('ads-manager', this.adsManagerLoaded.bind(this))
    this.player.on('adserror', this.onAdEvent.bind(this))
    if (!this.player.autoplay()) {
      this.player.on(['click', 'touchstart'], this.initializeAdDisplayContainer)
    }

    if (videojs.browser.IS_IOS || videojs.browser.IS_SAFARI) {
      this.player.on('adslog', this.onAdsLog.bind(this))
    }
    this.player.on('adbreakend', this.onAdBreakEnd.bind(this))
  }

  onAdBreakEnd (e) {
    videojs.log('Ads: AdBreakEnd Received')

    if (!this.player.ads.isLive()) {
      return
    }

    setTimeout(function () {
      if (!this.player.paused()) {
        this.player.removeClass('agnoplayer-hide-big-play-button')
        this.player.removeClass('vjs-waiting')
        return
      }

      videojs.log('Ads: Attempt to restart playback after adbreakend with delay')
      const muted = () => {
        videojs.log('Ads: Unmuted playback attempt fail. Attempt muted playback')
        const previouslyMuted = this.player.muted()
        this.player.muted(true)
        this.player.addClass('agnoplayer-autoplay-muted')
        const restoreMuted = () => {
          videojs.log('Ads: Muted playback failed. Restore muted state.')
          this.player.muted(previouslyMuted)
          this.player.removeClass('agnoplayer-hide-big-play-button')
          this.player.removeClass('vjs-waiting')
          if (!previouslyMuted) {
            this.player.removeClass('agnoplayer-autoplay-muted')
          }
        }
        const mutedPromise = this.player.play()
        if (!isPromise(muted)) {
          return
        }
        mutedPromise.catch(restoreMuted)
      }

      const promise = this.player.play()
      if (!isPromise(promise)) {
        return
      }
      promise.catch(muted)
      promise.then(() => {
        videojs.log('Ads: Playback succeeded')
        if (this.player.muted()) {
          this.player.addClass('agnoplayer-autoplay-muted')
        }
      })
      this.player.removeClass('agnoplayer-hide-big-play-button')
      this.player.removeClass('vjs-waiting')
    }.bind(this), 1500)
    this.player.addClass('agnoplayer-hide-big-play-button')
    this.player.addClass('vjs-waiting')
  }

  onAdsLog (e) {
    videojs.log('Ads: Received adslog event', e)

    // Catch ad error when response does not contain any valid ads.
    try {
      const adError = e.data.AdEvent.getAdData().adError
      const errCode = adError.getErrorCode()

      if (errCode === 1009) {
        window.AGNO.eventBus.trigger('agnoplay:vasterror', 'Ads: Received adserror 1009. Empty VAST response')
        this.player.trigger({
          type: 'adserror',
          data: {
            AdErrorEvent: {
              getError: function () { return adError }
            }
          }
        })
      } else {
        if (errCode) {
          window.AGNO.eventBus.trigger('agnoplay:vasterror', `Ads: Received adserror ${errCode}`)
        }
      }
    } catch (e) {
      // noop
    }

    if (videojs.browser.IS_IOS || videojs.browser.IS_SAFARI) {
      try {
        if (e.data.AdEvent.getAdData().adError.getErrorCode() === 1009) {
          videojs.log('Ads: Received adserror 1009. Empty VAST response')
          setTimeout(function () {
            if (!this.player.paused()) {
              this.player.removeClass('agnoplayer-hide-big-play-button')
              this.player.removeClass('vjs-waiting')
              return
            }

            videojs.log('Ads: Attempt to restart playback after error')
            const muted = () => {
              videojs.log('Ads: Unmuted playback attempt fail. Attempt muted playback')
              const previouslyMuted = this.player.muted()
              this.player.muted(true)
              this.player.addClass('agnoplayer-autoplay-muted')
              const restoreMuted = () => {
                videojs.log('Ads: Muted playback failed. Restore muted state.')
                this.player.muted(previouslyMuted)
                this.player.removeClass('agnoplayer-hide-big-play-button')
                this.player.removeClass('vjs-waiting')
                if (!previouslyMuted) {
                  this.player.removeClass('agnoplayer-autoplay-muted')
                }
              }
              const mutedPromise = this.player.play()
              if (!isPromise(muted)) {
                return
              }

              mutedPromise.catch(restoreMuted)
            }

            const promise = this.player.play()
            if (!isPromise(promise)) {
              return
            }
            promise.catch(muted)
            promise.then(() => {
              videojs.log('Ads: Playback succeeded')
              if (this.player.muted()) {
                this.player.addClass('agnoplayer-autoplay-muted')
              }
            })
            this.player.removeClass('agnoplayer-hide-big-play-button')
            this.player.removeClass('vjs-waiting')
          }.bind(this), 3000)
          this.player.addClass('agnoplayer-hide-big-play-button')
          this.player.addClass('vjs-waiting')
        }
      } catch (e) {
        // noop
      }
    }
  }

  initializeAdDisplayContainer () {
    this.player_.ima.initializeAdDisplayContainer()
    this.player_.off(['click', 'touchstart'], this.initializeAdDisplayContainer)
  }

  handleAdPlayPause (event) {
    // Toggle the play pause state of the ad in mobile when the ad is clicked since its not opening a new page
    if (videojs.browser.IS_ANDROID || videojs.browser.IS_IOS) {
      this.player.ima.controller.onAdPlayPauseClick()
    } else {
      this.player.ima.pauseAd()
    }
    if (this.player.adControlBar) {
      this.player.adControlBar.show()
    }
  }

  adsManagerLoaded () {
    this.player.trigger('cuepointchange')
    const events = [
      google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
      google.ima.AdEvent.Type.FIRST_QUARTILE,
      google.ima.AdEvent.Type.LOADED,
      google.ima.AdEvent.Type.MIDPOINT,
      google.ima.AdEvent.Type.IMPRESSION,
      google.ima.AdEvent.Type.PAUSED,
      google.ima.AdEvent.Type.RESUMED,
      google.ima.AdEvent.Type.STARTED,
      google.ima.AdEvent.Type.THIRD_QUARTILE
    ]

    for (let i = 0; i < events.length; i++) {
      this.player.ima.addEventListener(events[i], this.onAdEvent.bind(this))
    }

    this.nextAd = this.options_.ads.shift()
    this.player.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, this.playNextAd.bind(this))

    if (this.options_.moatPartnerCode && window.AGNO && window.AGNO.initMoatTracking) {
      // MOAT integration
      window.AGNO.initMoatTracking(this.player.ima.getAdsManager(), {
        partnerCode: this.options_.moatPartnerCode,
        viewMode: this.player.isFullscreen() ? google.ima.ViewMode.FULLSCREEN : google.ima.ViewMode.NORMAL
      },
      this.player.ima.controller.adUi.getAdContainerDiv()
      )
    }
  }

  onAdEvent = (event) => {
    if (event.type === 'adserror') {
      window.AGNO.eventBus.trigger('agnoplay:vasterror', `Ads: Error. adTagUrl: ${this.options_.adTagUrl}`)
    }
    const mappedEvent = this.eventMap_.find(item => item.adEvent === event.type)
    if (mappedEvent) {
      this.player.trigger(mappedEvent.analyticsEvent, event)
    }
  }

  playNextAd () {
    if (this.nextAd !== undefined) {
      this.loadVastURL(this.nextAd.sources)
      this.player.ima.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, this.playNextAd.bind(this))
    }
  }
}

videojs.registerPlugin('adsManager', AdsManager)
