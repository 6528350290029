import videojs from 'video.js'
import './ModalContainer'
import './ModalSettingsContainer'
import './ContainerButton'
import './PlayerPreviousButton'
import './PlayToggle'
import './PlayerNextButton'

const Component = videojs.getComponent('Component')

/**
 * Control Player Button Container
 *
 * @extends Component
 * @class ControlPlayerButtonContainer
 */
class ControlPlayerButtonContainer extends Component {
  createEl (tag = 'div', props = {}, attributes = {}) {
    props = Object.assign({
      className: this.buildCSSClass()
    }, props)

    return super.createEl(tag, props, attributes)
  }

  buildCSSClass () {
    return 'agnoplayer-control-playerbutton-container'
  }
}

/**
 * Default options for `ControlPlayerButtonContainer`
 *
 * @type {Object}
 * @private
 */
ControlPlayerButtonContainer.prototype.options_ = {
  children: [
    'playerPreviousButton',
    'playToggle',
    'playerNextButton'
  ]
}

Component.registerComponent('ControlPlayerButtonContainer', ControlPlayerButtonContainer)
export default ControlPlayerButtonContainer
